.listHeader {
    /*color: orchid;*/
    /*color: springgreen;*/
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px
}

.listHeader svg {
    fill: var(--contrast-color);
}

.addItemButton {
    background-color: var(--background-color);
    height: 100px;
    width: 100px;
    margin: 20px auto;

    display: flex;
    align-items: center;
    justify-content: center;


    border-radius: 100%;
}

.addItemButtonFullscreenWrapper {
    width: 100vw;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    justify-content: center;

    scroll-snap-align: start;
    scroll-snap-stop: always;
}

.items {
    padding: 20px 20px 80px 20px;
    max-height: calc(100vh - 170px);
    overflow: auto;
}

.listItem {
    background-color: var(--background-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
}

/* CHECK */

.checkable {
    font-size: 20px;
    text-align: left;
    position: relative;
    margin-bottom: 10px;
    min-height: 40px;
    transition:
            color 400ms ease-out,
            padding 400ms ease-out,
            background-color 400ms ease-out;
}

.checkable p {
    flex: 1;
    margin: 0 10px 0 0;
}

.checkable svg {
    margin: 0 10px;
}

.checkable.unchecked {
    padding: 12px 0;
}

.checkable.checked {
    padding: 8px 0;
    color: var(--contrast-color-faded);
    background-color: var(--background-color-faded);
}

.checkable svg {
    transition: fill 400ms ease-out;
}

.checkable.checked svg path {
    fill: var(--contrast-color-faded)
}

/* EDIT */

.editableListContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-color);
    z-index: 3;
}

.draggableListItem {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.draggableListItem.dragging {
    transform: translate(0, -5px);
    -webkit-box-shadow: 0px 10px 5px -4px var(--Shadow-color);
    -moz-box-shadow: 0px 10px 5px -4px var(--Shadow-color);
    box-shadow: 0px 10px 5px -4px var(--Shadow-color);
    border-radius: 20px;
}

.editableListItem {
    min-height: 40px;
    width: calc(100% - 20px);
    padding: 12px 45px;
    justify-content: center;
}

.editableListItem.dragging {
    background-color: var(--dragging-color);
}

.dragHandle,
.deleteButton {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deletableListItem .checkBox {
    position: absolute;
    left: 5px;
}

.deletableListItem svg.checkBox path {
    fill: #444;
}

.dragHandle {
    right: 1px;
}

.deleteButton {
    right: 5px;
    z-index: 2;
}

/*.editableListItem.editing {*/
/*    webkit-box-shadow: 0 0 10px 5px var(--editing-color);*/
/*    -moz-box-shadow: 0 0 10px 5px var(--editing-color);*/
/*    box-shadow: 0 0 10px 5px var(--editing-color);*/
/*}*/

/*.editableListItem.deleting {*/
/*    webkit-box-shadow: 0 0 10px 5px var(--deleting-color);*/
/*    -moz-box-shadow: 0 0 10px 5px var(--deleting-color);*/
/*    box-shadow: 0 0 10px 5px var(--deleting-color);*/
/*}*/
