.App {
  --primary-color: #222;
  --contrast-color: white;
  --contrast-color-faded: #666;

  --button-color: #444;

  --background-color: black;
  --background-color-faded: #111;


  --dragging-color: #23273d;
  /*--editing-color: dodgerblue;*/
  /*--deleting-color: red;*/

  --Shadow-color: rgba(0,0,0,0.75);
}

.App.light {
  --primary-color: #eee;
  --contrast-color: #111;
  --contrast-color-faded: #bbb;

  --button-color: #bbb;

  --background-color: white;
  --background-color-faded: #fafafa;


  --dragging-color: #b8c3ff;
  /*--editing-color: dodgerblue;*/
  /*--deleting-color: red;*/
}

.App.pink {
  --primary-color: #ffc8ef;
  --contrast-color: #630047;
  --contrast-color-faded: #b85d9e;

  --button-color: #dc8fc6;

  --background-color: #fff5fa;
  --background-color-faded: #ffe4f5;


  --dragging-color: #ff9ee2;
  /*--editing-color: dodgerblue;*/
  /*--deleting-color: red;*/
}

button, input, select, textarea {
  font: inherit;
}

svg path {
  fill: var(--contrast-color)
}

.App {
  text-align: center;
  color: var(--contrast-color);
}

.appContent {
  background-color: var(--primary-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.scrollyContent {
  height: 100%;
  display: flex;
}

.settings {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: var(--primary-color);
}

.sortList {
  min-width: 100vw;
}

.pane {
  scroll-snap-align: start;
  scroll-snap-stop: always;

  max-height: 100vh;
  min-width: 100vw;
}

.App button {
  background-color: var(--background-color);
  color: var(--contrast-color);
}

button:not(.noStyle) {
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
}

button.noStyle {
  background-color: rgba(0, 0, 0, 0);
  border: none
}

button.yes {
  background-color: lightgreen;
  color: black;
}

button.no {
  background-color: coral;
  color: black;
}


.checkBox path {
  transition: d 250ms;
}

.checkBox.checked path {
  d: path("m 11.120115,58.317116 7.120334,6.317823 6.026283,5.413084 26.863979,23.785172 0.08968,-0.0065 73.610599,-75.912909 0.19168,-0.208115 0.23209,-0.218791 0.25933,-0.293027 1.0137,-1.034147 -0.0487,17.386797 -74.521188,89.035537 -0.04318,-0.001 -40.699193,-50.569883 z");
}