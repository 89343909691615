.navButton {
    position: fixed;
    left: 10px;
    bottom: 10px;

    height: 50px;
    width: 50px;
    border-radius: 100%;

    background-color: var(--primary-color);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 2;
    border: 2px solid var(--background-color)
}

.nav {
    position: fixed;
    left: -100vw;
    right: 100vw;
    bottom: 0;
    z-index: 1;
    background-color: var(--background-color);
    transition: left 400ms, right 400ms;

    border-radius: 0 15px 0 0;

    padding: 0 20px 20px 20px;
    max-height: 100vh;
    overflow: auto;

}

.nav-expanded {
    left: 0;
    right: 0;
}

.nav h1 {
    position: sticky;
    top: 20px;
    background-color: var(--background-color);
}