
.modalWrapper,
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modalWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;
    z-index: 10;

}
.modalOverlay {
    background-color: var(--background-color);
    opacity: .7;
    z-index: 1;
}

.modal {
    max-height: 70vh;
    background-color: var(--primary-color);
    padding: 20px;
    border-radius: 5px;
    z-index: 20;

    webkit-box-shadow: 0 0 20px 10px var(--background-color);
    -moz-box-shadow: 0 0 20px 10px var(--background-color);
    box-shadow: 0 0 20px 10px var(--background-color);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal h3 {
    margin-top: 0;
}

.dummy {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.dummy,
.modalTextInput {
    font: unset;
    font-size: 24px;
    padding: 0;
}

.modalTextInput {
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0);
    color: var(--contrast-color);
    text-align: center;
    border: none;
    outline: none;
}

.deleteModal {
    font-size: 20px;
}

.deleteModal .buttons button {
    margin: 5px;
}
