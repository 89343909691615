.settings {
    padding-top: 120px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.copyListsButton {
    height: 40px;
    width: 40px;
    position: fixed;
    top: 0;
    right: 0;
}

.settings .items {
    min-height: unset;
    padding-top: 0;
}

.settingsHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.closeSettingsButton {
    position: fixed;
    top: 20px;
    left: 15px;
}

.colorSettings {
    margin-top: 85px;
    margin-bottom: 10px;
}

.colorSettings button {
    font-weight: bold;
    margin: 4px;
    border: 2px solid var(--contrast-color-faded);
    outline: none;
}

.colorSettings button.active {
    border: 2px solid var(--contrast-color)
}


.matrix {
    padding: 0 20px;
}

.draggableList {
    min-height: 40px;
    background-color: var(--background-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    margin-bottom: 10px;
    padding: 5px;
}


.draggableList.dragging {
    transform: translate(0, -5px);
    -webkit-box-shadow: 0px 10px 5px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 5px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 5px -4px rgba(0,0,0,0.75);
    border-radius: 20px;
    background-color: var(--dragging-color);
}

.sortCheckWrapper {
    display: inline-block;
    margin: 10px 0;
}

.sortCheck {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sortCheck svg {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

/*.sortCheck svg path {*/
/*    transition: d .25s;*/
/*}*/

/*.sortCheck.checked svg path {*/
/*    d: path("m 9.9729899,75.35128 41.0002991,49.70757 75.430051,-91.472709 0.29814,-17.291543 -7.3076,7.548047 -2.93872,3.009504 -2.68161,2.745461 -2.11059,2.155184 L 51.274401,93.959178 10.247529,57.841859 Z");*/
/*}*/